import React from "react"

const HeroSection = ({
  title,
  titleEC,
  description1,
  description1EC,
  description2,
  description2EC,
}) => {
  return (
    <div className="synkli-section--horizontal-space synkli-section--vertical-space">
      <h1
        className={`text-[#0A1E46] text-[30px] md:text-[40px] lg:text-[58px] leading-[1.2em] font-[700] max-w-[1200px] ${titleEC}`}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <div className="flex flex-col lg:flex-row gap-5 lg:gap-16 mt-8">
        {description1 && (
          <p
            className={`flex-[1] text-[#0A1E46] text-[18px] lg:text-[20px] leading-[1.4em] font-[400] ${description1EC}`}
          >
            {description1}
          </p>
        )}
        {description2 && (
          <p
            className={`flex-[1] text-[#0A1E46] text-[18px] lg:text-[20px] leading-[1.4em] font-[400] ${description2EC}`}
          >
            {description2}
          </p>
        )}
      </div>
    </div>
  )
}

export default HeroSection
